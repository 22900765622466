import request from'@/utils/request.js'

export default{
    Query(data) {
        return request({
            url: '/Service/Query',
            method: 'post',
            data
        })
    }    
}