import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    headerActive:sessionStorage.headerActive || 1
  },
  mutations: {
    changeMenu(state,e){
      state.headerActive = e
      sessionStorage.headerActive = e
    }
  },
  actions: {},
  modules: {},
});
