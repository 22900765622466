<template>

  <div class="home">
    <homeSwiper :list="bannerList"/>
    <div class="home_content">
    <!-- <div class="companyIntro" style="margin-top:50px;">
        <div class="conT">
          <div class="title">{{introd.title}}</div>
          <div class="subtitle">{{introd.sTitle}}</div>
        </div>
        <div class="introduction">
          <div style="background: #fff;width:40%;height:350px;">
            <img src="@/assets/tongtong/1/组 2232@2x.png" alt="" style="width:auto;height:100%;">
          </div>
          <div class="introduction_text" style="padding-left: 40px;">
            <div class="introduce">
              {{introd.sContent}}
            </div>
            <div class="getMore">
              <button @click="toAbout">了解更多></button>
            </div>
          </div>
        </div>
    </div> -->
    <div class="mainService">
      <div class="mainService_content">
        <!-- <div class="conT">
          <div class="title">核心服务</div>
        </div> -->
        <div style="width:100%;">
          <img src="@/assets/yibo/1/组 489@2x.png" alt="" style="width:100%;height:auto;" @click="toAbout">
        </div>
      </div>
    </div> 
    <div class="mainService">
      <div class="mainService_content">
        <!-- <div class="conT">
          <div class="title">核心服务</div>
        </div> -->
        <div style="width:100%;">
          <img src="@/assets/yibo/1/组 2240@2x.png" alt="" style="width:100%;height:auto;">
        </div>
      </div>
    </div> 
    <div class="mainService">
      <div class="mainService_content">
        <!-- <div class="conT">
          <div class="title">核心服务</div>
        </div> -->
        <div style="width:100%;">
          <img src="@/assets/yibo/1/组 491@2x.png" alt="" style="width:100%;height:auto;">
        </div>
      </div>
    </div> 
    <!-- <div class="companyIntro" >
        <div class="introduction">
          <div class="introduction_text" style="flex:1">
            <div class="conT" >
              <div class="title">同通智慧云平台</div>
            </div>
            <div class="introduce">
              同通智慧云平台是具有 ，作为城市级整体智慧停车管理平台入口，集成AI人工智能、IOT物联网、云端大数据三大核心技术实现城市静态交通监控及管理，将城市所有停车场统一纳入平台进行互联互通，作为城市停车赋能、为车主提供无感智慧的停车体验，从而实现城市动态交通和静态交通的协调。
            </div>
            
          </div>
          <div style="width:40%;">
            <img src="@/assets/tongtong/1/组 1140@2x.png" alt="" style="width:80%;height:auto;">
          </div>
        </div>
    </div> -->
    <div class="mainService">
      <div class="mainService_content">
        <!-- <div class="conT">
          <div class="title">服务优势</div>
        </div> -->
        <div style="width:100%;">
          <img src="@/assets/yibo/1/组 2241@2x.png" alt="" style="width:100%;height:auto;">
        </div>
      </div>
    </div>
    <div class="apply">
      <div class="apply_content">
        <!-- <div class="apply_top">
          <div class="conT">
            <div class="title">应用领域</div>
            <div class="subtitle"></div>
          </div>
          <div style="color:#999999;font-size:18px;cursor: pointer;" @click="readMoreApply">
              查看更多
              <img src="@/assets/img/readMore.png" alt="" width="20" height="20" style="vertical-align:middle;">
          </div>
        </div> -->
        <div style="width:100%;">
          <img src="@/assets/yibo/1/组 2242@2x.png" alt="" style="width:100%;height:auto;"  @click="readMoreApply">
        </div>
      </div>
    </div> 
  </div>
</div>
</template>

<script>
import banner from '@/api/banner.js'
import service from '@/api/service.js'
import About from '@/api/about.js'
import news from '@/api/news.js'
import selects from '@/api/selects.js'
import homeSwiper from '@/components/banner.vue'
export default {
  name: "Home",
  components: {
    // HelloWorld,
    homeSwiper
  },
  data(){
    return {
      introd: {title:'公司简介', sTitle:'科技创造未来  服务创造未来', sContent:"厦门同通科技有限公司成立于2010年，坐落于厦门市软件园三期，目前业务范围已覆盖全国多个省市地区。早期专门从事运营商相关业务 ，2017年开始主要研发、 销售、服务于智慧城市交通基础设施设备、提供整套停车解决方案。本公司依托物联网、云计算 大数据等尖端技术，致力于发展智慧社区、停车场智慧化管理等创新产品。公司秉持以服务至上，合作共享、便捷实用的服务理念 ，为社区、商城、学校、写字楼，政企单位等提供智慧化解决方案。公司拥有一支技术实力雄厚、职业有素、志向远大的团队。在当前快速发展的移动物联时代，以专业，创新，引领，智慧的发展理念，用心解决用户最迫切、最实际的需求，以优质的服务，先进的技术为用户提供最完美的服务。"},
      caseType: [],
      bannerList:[1],
      newslist:[
        // {id:1,year:"2021",month:"01",title:"热强烈庆祝我司智慧停车再次拿下一座城市",text:"医院的机动车停车设施不能满足停车需求"},
        // {id:2,year:"2021",month:"01",title:"热强烈庆祝我司智慧停车再次拿下一座城市",text:"医院的机动车停车设施不能满足停车需求"},
        // {id:3,year:"2021",month:"01",title:"热强烈庆祝我司智慧停车再次拿下一座城市",text:"医院的机动车停车设施不能满足停车需求"},
        // {id:4,year:"2021",month:"01",title:"热强烈庆祝我司智慧停车再次拿下一座城市",text:"医院的机动车停车设施不能满足停车需求"},
      ],
      service1:[],
      service2:[],
    }
  },
  mounted(){
    // this.initBanner()
    // this.initIntrod()
    // this.initCase()
    // this.initService1()
    // this.initService2()
    // this.initNews()
  },
  methods: {
    //核心服务
    initService1(){
      service.Query({type:10}).then(resp=>{
        if(resp.data.code == 200){
          console.log("核心服务：",resp.data.data.data)
          this.service1 = resp.data.data.data
        }
      })
    },
    //服务优势
    initService2(){
      service.Query({type:11}).then(resp=>{
        if(resp.data.code == 200){
          console.log("服务优势：",resp.data.data.data)
          this.service2 = resp.data.data.data
        }
      })
    },
    initIntrod(){
      About.QueryByHome({}).then(resp=>{
        if(resp.data.code == 200){
          console.log(resp.data.data.data,"简介")
          this.introd = resp.data.data.data
        }
        
      })
    },
    initCase(){
      selects.QueryParameterItem({code:'102'}).then(resp=>{
        if(resp.data.code == 200){
          console.log(resp.data.data.data,"案例")
          this.caseType = resp.data.data.data
        }
        
      })
    },
    initNews(){
      news.Query({pageIndex: 1,pageSize: 4,type: null}).then(resp=>{
        if(resp.data.code == 200){
          this.newslist = resp.data.data.home
          console.log(resp.data.data.home,"新闻")
        }
      })
    },
    toAbout(){
      this.$store.commit('changeMenu',5)
      this.$router.push({
        name:"about"
      })
    },
    toCase(type){
      console.log(type,"类型")
      this.$router.push({
        name:"moreCase",
        query: {
          type
        }
      })
    },
    goDetail(item){
      this.$router.push({
        name:"newsDetail",
        query: {
          detail: JSON.stringify(item)
        }
      })
    },
    readMoreNews(){
      this.$router.push({
        name:"moreNews"
      })
    },
    readMoreApply(){
      this.$router.push({
        name:"moreCase"
      })
    },
    initBanner(){
      banner.Query({}).then(resp=>{
        if(resp.data.code == 200){
            console.log(resp.data.data.data,"bannner ")
            this.bannerList = resp.data.data.data
        }
      })   
    }
  },
  
};
</script>
<style scoped lang="less">
.conT {
  text-align: left;
  padding: 10px 0;
  .title {
    font-size: 34px;
    font-weight: bold;
    color:#000000FF;
    height: 45px;
    line-height: 35px;
  }
  .subtitle {
    font-size: 14px;
    color:#999999FF;
    height: 19px;
    line-height: 35px;
  }
  .line {
    width: 30px;
    height: 20px;
    border-bottom: 6px solid #287bffff;
  }
}
.home {
  background: #FFFFFFFF;
  .home_content {
    // width: 1200px;
    width: 60%;
    // min-width: 1200px;
    margin: 0 auto;
    // width: 1200px;
    // min-width: 800px;
    // margin: 0 auto;
    .companyIntro {
      // padding: 40px 0;
      .introduction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        &_text {
          
          text-align: left;
          flex: 1;
        }
        .introduce {
          text-indent: 1rem;
          color: #666666FF;
          font-size: 18px;
          margin: 10px 0 20px 0;
          text-align: justify;
        }
        .getMore {
          button {
            cursor: pointer;
            background: #287BFFFF;
            color: white;
            outline: none;
            border: none;
            padding: 4px 10px;
            font-size: 16px;
          }
        }
      }
    }
    .mainService {
      margin-bottom: 20px;
      &_content {
        padding: 20px 0;
        &_img {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          img {
            box-shadow: 0px 0px 20px rgba(40, 123, 255, 0.2);
            border-radius: 10px;
            width: 22%;
            margin-right: 4%;
            &:nth-child(4n+4){
              margin-right: 0;
            }
          }
        }
        .content {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .itemOne {
            // width: 16%;
            flex: 1;
            padding: 30px 0;
            margin: 20px 0;
            margin-right: 5%;
            border-radius: 10px;
            box-shadow: 0px 0px 20px rgba(40, 123, 255, 0.2);
            .name {
              font-size: 18px;
              color: rgba(51, 51, 51, 1);
              font-weight: bold;
              line-height: 50px;
              height: 50px;
            }
            .subname {
              color: rgba(102, 102, 102, 1);
              font-size: 12px;
            }
            &:nth-child(5n+5){
                margin-right: 0;
            }
          }
        }
        .content2 {
          display: flex;
          justify-content: space-between;
          // flex-wrap: wrap;
          .itemOne {
            &:last-child{
                margin-right: 0;
            }
          }
        }
      }
    }
    .apply {
      &_content {
        .apply_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &_img {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .onecon{
            cursor: pointer;
            width: 22%;
            margin-right: 4%;
            margin-bottom: 4%;
            position: relative;
            &_img {
              width: 100%;
              height: 100%;
            }
            &:nth-child(4n+4) {
              margin-right: 0;
            }
            .relativeC {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              color: white;
              font-size: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          
          
        }
      }
    }
    .news {
      margin-bottom: 30px;
      &_c {
        .news_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .news_main {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_img {
            position: relative;
            img {
              width: auto;
              height: 500px;
            }
            .afters {
              position: absolute;
              bottom: 3px;
              left: 0;
              width: 100%;
              height: 160px;
              background: rgba(0, 0, 0, 0.5);
              display: inline-block;
              color: white;
              .showtitle {
                text-align:left;
                padding: 20px;
                .big {
                  font-size: 34px;
                }
                .small {
                  font-size: 14px;
                }
              }
            }
          }
          &_right {
            flex: 1;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 500px;
            .news_item {
              display: flex;
              flex: 1;
              cursor: pointer;
              align-items: center;
              border-bottom: 1px dashed #B1B1B1;
              .newslist_l {
                width: 100px;
                color: #666666;
                font-size: 24px;
                text-align: center;
                .month {
                  font-size: 24px;
                  font-weight: bold;
                  text-decoration: underline;
                }
                .year {
                  font-size: 18px;
                }
              }
              .newslist_r {
                color:#333;
                flex:1;
                text-align: left;
                max-width: 600px;
                .news_title {
                  font-size: 18px;
                  font-weight: bold;
                  height: 30px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .news_text {
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
  
}
</style>
