<template>
  <div class="fixHeader">
    <div class="header">
      <div>
        <img height="30" src="@/assets/yibo/1/组 472@2x.png" alt="" >
      </div>
      <div class="header_menu">
        <div class="menuItem" :class="this.$store.state.headerActive==1?'active':''" @click="clickMenu(1)">首页</div>
        <div class="menuItem" :class="this.$store.state.headerActive==2?'active':''" @click="clickMenu(2)">案例</div>
        <div class="menuItem" :class="this.$store.state.headerActive==3?'active':''" @click="clickMenu(3)">产品</div>
        <div class="menuItem" :class="this.$store.state.headerActive==4?'active':''" @click="clickMenu(4)">关于我们</div>
        <!-- <div class="menuItem" :class="this.$store.state.headerActive==5?'active':''" @click="clickMenu(5)">关于我们</div> -->
      </div>
    </div>  
  </div>
  
</template>

<script>
// import {mapMutations} from 'vuex'
export default {
  name: 'Header',
  // props: {
  //   msg: String
  // }
  data(){
    return {
      // isclick:1
    }
    // let isclick = ref(1)
    // const clickMenu =(e)=>{
    //   isclick.value = e
    // }
    // return {
    //   isclick,
    //   clickMenu
    // }
  },
  methods:{
    clickMenu(e){
      // this.isclick = e
      this.$store.commit('changeMenu',e)
      scrollTo(0,0);
      if(e==1){
        this.$router.push({
          name:"Home"
        })
      }
      else if(e==2){
        this.$router.push({
          name:"case"
        })
      }
      else if(e==3){
        this.$router.push({
          name:"product"
        })
      }
      // else if(e==4){
      //   this.$router.push({
      //     name:"hardware"
      //   })
      // }
      else if(e==4){
        this.$router.push({
          name:"about"
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.fixHeader {
  height: 80px;
  width: 100vw;
  box-shadow: 0px 0px 20px rgba(40, 123, 255, 0.2);
  position: fixed;
  z-index: 999;
  top:0;
  left:0;
  // transform: translate(-50%, 0%);
  background: white;
  .header {
    height: 80px;
    width: 60vw;
    min-width: 800px;
    // min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_menu {
      display: flex;
      .menuItem {
        color: #333333FF;
        margin-left:60px;
        padding: 4px 0;
        font-size: 20px;
        cursor: pointer;
        &.active {
          color: #000000FF;
          font-weight: bold;
          border-bottom: 3px solid #287BFFFF;
        }
      }
    }
  }  
}

</style>
