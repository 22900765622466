import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/moreNews",
    name: "moreNews",
    component: () =>
      import("../views/Home/moreNews.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import("../views/Home/newsDetail.vue"),
  },
  {
    path: "/case",
    name: "case",
    component: () =>
      import("../views/Case/index.vue"),
  },
  {
    path: "/moreCase",
    name: "moreCase",
    component: () =>
      import("../views/Case/moreCase.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import("../views/product/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/About/index.vue"),
  },
];

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
});

export default router;
