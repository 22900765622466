import axios from 'axios'
import router from '@/router'
// import { Message } from 'element-ui';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
    headers: {
      post: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      put: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }
})

service.interceptors.request.use(
  config => {    
    if (localStorage.user && JSON.parse(localStorage.user).token) { 
      config.headers.author = JSON.parse(localStorage.user).token;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

let isFail = 0

// http response 拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code === 401) {
      if (isFail < 1) {
        // Message({
        //   type: 'error',
        //   message: '登录超时，请重新登录',
        //   duration: 3000
        // })
        alert("登录超时，请重新登录")
        router.replace('/');
        isFail++
      }
    }
    // console.log("拦截响应",response)
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default service