<template>
    <div class="wrapper" >
        <!-- 导入图片数据数组 -->
        <swiper :options="swiperOption" v-if="list.length" > 
            <swiper-slide v-for="(item,index) of list" :key="index">
                <img class="swiper-img" src="@/assets/yibo/1/4554d76bdd9e745de4e10f58ba5875a.png" /> 
                <!-- 循环数组轮播图片 -->
            </swiper-slide>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
            <!-- <div class="swiper-pagination"  slot="pagination"></div> //分页器 -->
        </swiper>
    </div>   
</template>

<script>

export default {
    name:"homeSwiper",
    props: ['list'],
    data (){
        return {
            swiperOption:{       //轮播插件配置参数
                pagination:{         //分页器
                    el:'.swiper-pagination',
                    clickable:true
                },
                loop:false,     //循环
                autoplay:{      //自动播放
                    delay:3000,
                    disableOnInteraction:false
                },
                speed:3000     //播放速度
            },
            // list:[{
            //     go: null,
            //     stop: 5,
            //     title: null,
            //     url:""
            // }]
        }
    },
    methods:{
        linkImg(e){
            
            if(e){
                console.log(e,"跳转页面")
                //window.open(e,'_blank')在新窗口打开外链接
       		    window.location.href = e;   // 在本页面打开外部链接
            }
        }
    }
    
    // props:{
    //     list
    // }
    
}
</script>

<style lang="less" scoped>
    .wrapper /deep/ .swiper-pagination-bullet-active {
        background: #fff;
    }
    .wrapper {
        // background :#eee;
        overflow :hidden;
        .swiper-img {
            width:100%;
            height :auto;
        }    
    }   
</style>