<template>
  <div id="app">
    <Header/>
    <!-- <homeSwiper /> -->
    <router-view style="margin-top:80px;"/>
    <Bottom/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Bottom from './components/bottom.vue'
// import homeSwiper from './components/banner.vue'
export default {
  name: 'App',
  components: {
    Header,
    Bottom,
    // homeSwiper
  },
  data(){
    return {

    }
  }
}
</script>
<style lang="less">
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Microsoft YaHei,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  // overflow: auto;
  // width: 100vw;
}

</style>
